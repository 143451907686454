<template>
  <div
    v-loading="loading"
    :element-loading-text="loading_text"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
    :fullscreen="true"
  >
    <div
      class="hold-body"
      v-if="getErrorCode === 'HOLD_WORKSPACE' || getResendVerificationEmail"
    >
      <div class="hold-card">
        <div class="action-navbar">
          <div class="goto mt-1 mr-1">
            <el-link type="primary" @click="goToSignIn">Go to Home</el-link>
            <el-link class="ml-1" type="primary" @click="logout"
              >Logout</el-link
            >
          </div>
        </div>

        <div class="inner-body" v-if="getErrorCode === 'HOLD_WORKSPACE'">
          <img src="@/assets/img/Hold.svg" alt="icon" class="hold-logo mt-2" />
          <p class="hold-head-text">Email has to be verified</p>
          <el-link
            class="hold-sub-head"
            type="primary"
            @click="resendVerificationMail"
            >Click here to send activation link</el-link
          >
        </div>
        <div class="inner-body" v-else-if="getResendVerificationEmail">
          <img
            src="@/assets/img/Success.svg"
            alt="icon"
            class="hold-logo mt-2"
          />
          <p class="hold-head-text">Link sent to your email successfully!</p>
        </div>
      </div>
    </div>

    <div class="hold-body" v-else-if="failed">
      <div class="hold-card">
        <div class="action-navbar">
          <div class="goto mt-1 mr-1">
            <el-link type="primary" @click="goToSignIn">Go to Home</el-link>
            <el-link class="ml-1" type="primary" @click="logout"
              >Logout</el-link
            >
          </div>
        </div>
        <div class="inner-body">
          <img src="@/assets/img/Hold.svg" alt="icon" class="hold-logo mt-2" />

          <p class="hold-head-text">Some Thing went wrong...</p>
        </div>
      </div>
    </div>

    <el-skeleton v-else :rows="20" animated />
  </div>
</template>

<script>
import appConfig from "@/config/app";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      current_route: "",
      loading: false,
      baseURL: window.location.origin + this.$route.path,
      logoutBaseURL: window.location.origin + "/signin",
      serverBaseURL: appConfig.AUTHFAST_BASE_URL,
      code: "",
      loading_text: "Signing In....",
      failed: false,
      email: "",
    };
  },

  computed: {
    ...mapGetters("errors", ["getErrors"]),
    ...mapGetters("auth", [
      "getAuthenticationStatus",
      "getAccountNotVerified",
      "getAccountNotVerifiedEmail",
      "getWorkspaceList",
      "getAuthenticatedUser",
      "getUserType",
      "getErrorCode",
      "getErrorData",
      "getResendVerificationEmail",
    ]),
    ...mapGetters("sso", [
      "getCodeExchangeData",
      "getCodeExchangeStatus",
      "getSSOExistedUserData",
    ]),
  },
  mounted() {
    this.loading = true;
    this.failed = false;
    this.current_route = window.location.href;
    this.email =
      this.$route.query &&
      this.$route.query.email &&
      this.$route.query.email.length
        ? this.$route.query.email
        : null;
    // we have to move redirect to sso url
    this.checkLogin();
  },

  methods: {
    logout() {
      window.location.href =
        this.serverBaseURL +
        "/logout/client?redirect_url=" +
        this.logoutBaseURL;
    },
    goToSignIn() {
      this.$router.push("/signin");
    },
    login() {
      let url =
        this.serverBaseURL + "/signin/client?redirect_url=" + this.baseURL;

      window.location.href = url;
      window.localStorage.clear();
    },
    loginUserDetials() {
      let user = window.localStorage.getItem("userDetials");
      if (user) {
        user = JSON.parse(user);
        return user.profile.email;
      }
      return "";
    },
    async resendVerificationMail() {
      try {
        this.loading = true;
        await this.$store.dispatch("auth/resendInviteEmailToUser", {
          email: this.getSSOExistedUserData.email.toLowerCase(),
          company: this.getSSOExistedUserData.workspace[0].company_id,
        });

        if (this.getResendVerificationEmail) {
          this.$notify.success({
            title: "Success",
            message: "Verification mail sent to your email",
          });
          this.signin = {
            email: "",
            phone: "",
            password: "",
            remember_me: "",
          };
          this.$store.commit("errors/reset", null, { root: true });
          this.$store.commit("auth/setErrorCode", "", { root: true });
          this.$store.commit("auth/setErrorData", "", { root: true });
        }
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.$notify.error({
          title: "Error",
          message: "Error at sending verification mail",
        });
      }
    },
    async checkLogin() {
      let params = new URL(document.location).searchParams;
      let code = params.get("code");
      let redirect = params.get("next_path");
      this.email = params.get("email");
      this.loading = false;
      if (code && !redirect) {
        this.code = code;
        this.loading = true;

        this.loading_text = "Verifying...";
        await this.$store.dispatch("sso/fetchTokenFromCode", { code });
        // we need to send it to backend
        if (
          this.getCodeExchangeStatus &&
          this.getCodeExchangeData &&
          this.getCodeExchangeData.status_code == "EXISTED_USER_LOGIN_SUCCESS"
        ) {
          this.currentWorkspace = this.getAuthenticatedUser.current_workspace;
          await this.fetchUserData();
          if (
            this.currentWorkspace.plan_type != "INDIVIDUAL" &&
            this.currentWorkspace.user_type
          ) {
            await this.getCompanyUserType();
          }
          this.isResent = false;
          await this.$store.dispatch("auth/fetchProfile");
          this.$router.push({ path: "/documents" });
        } else if (
          this.getCodeExchangeStatus &&
          this.getCodeExchangeData.status_code == "NEW_USER"
        ) {
          this.$router.push({ path: "/sso-signup" });
        } else {
          this.loading = false;
          this.failed = true;
        }

        this.loading = false;
      } else if (redirect) {
        this.$router.push({ path: redirect, query: { already: true } });
      } else {
        this.login();
      }
    },

    async fetchUserData() {
      this.loading = true;
      await this.$store.dispatch(
        "auth/fetchUsersWithEmail",
        this.getAuthenticatedUser.email
      );
      this.loading = false;
      if (this.getWorkspaceList) {
        this.userWorkspaceList = this.getWorkspaceList;
        let hasIndividual = this.getAuthenticatedUser.workspace.findIndex(
          (u) => u.plan_type == "INDIVIDUAL"
        );
        if (hasIndividual != -1) {
          let individualWorkspace = {};
          if (this.getAuthenticatedUser.workspace[hasIndividual].company_id) {
            this.$set(
              individualWorkspace,
              "_id",
              "" + this.getAuthenticatedUser.workspace[hasIndividual].company_id
            );
          } else {
            this.$set(individualWorkspace, "_id", "0");
          }
          this.$set(individualWorkspace, "name", "INDIVIDUAL");
          this.userWorkspaceList[hasIndividual]["company_id"] =
            individualWorkspace;
          if (this.currentWorkspace.plan_type == "INDIVIDUAL") {
            if (this.getAuthenticatedUser.workspace[hasIndividual].company_id) {
              this.currentWorkspace["company_id"] =
                "" +
                this.getAuthenticatedUser.workspace[hasIndividual].company_id;
            } else {
              this.currentWorkspace["company_id"] = "0";
            }
          }
        }
        this.$store.commit("auth/setActiveWorkspace", this.currentWorkspace, {
          root: true,
        });
        this.$store.commit("auth/setWorkspaceList", this.userWorkspaceList, {
          root: true,
        });
      } else {
        this.userWorkspaceList = [];
      }
    },
    async getCompanyUserType() {
      try {
        await this.$store.dispatch(
          "auth/getUserTypeById",
          this.currentWorkspace.user_type
        );
        if (this.getUserType) {
          console.log(this.getUserType);
        }
      } catch (err) {
        console.log(err);
        this.$notify.error({
          title: "Error",
          message: err,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.hold-body {
  margin-top: 10%;
  text-align: center !important;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .hold-card {
    @media (min-width: 749px) {
      width: 749px;
      min-height: 300px;
    }
    width: 100%;
    min-height: 360px;
    height: 100%;
    border: 1px solid rgba(203, 214, 226, 0.5);
    align-items: center;
    .hold-logo {
      width: 100px;
    }
    .hold-head-text {
      font-weight: 500;
      font-size: 20px;
      color: #212529;
    }
    .hold-sub-head {
      // color: #303133;
      font-weight: 400;
      font-size: 16px;
    }
    .hold-actions {
      display: inline !important;
    }
    .inner-body {
      text-align: center;
    }
    .action-navbar {
      width: 100% !important;
      height: 45px !important;
      .goto {
        float: right;
      }
    }
  }
}
</style>